// DashboardTile.js
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import './dashboard-tile.css';

function DashboardTileTotalBanks({ title, collectionPath }) {
  const [data, setData] = useState(null);

  
  useEffect(() => {
    // Function to fetch data from the specified Firestore collection
    const fetchData = async () => {
      try {
        const db = getFirestore(); // Initialize Firestore
        const dataCollection = collection(db, collectionPath);
        const snapshot = await getDocs(dataCollection);
        const dataSize = snapshot.size; // Assuming we want the count of documents
        setData(dataSize);
      } catch (error) {
        console.error(`Error fetching data for ${title}:`, error);
        setData(0); // Fallback value on error
      }
    };

    fetchData();
  }, [collectionPath, title]);

  // Format the number to one decimal place with "K" if it’s in thousands
  const formatNumber = (num) => {
    if (num >= 1000) {
      return `${(num / 1000).toFixed(1)}K`;
    }
    return num;
  };

  return (
    <div className="dashboard-tile">
      <div className="tile-title">{title}</div>
      <div className="tile-number">{data !== null ? formatNumber(data) : '...'}</div>
    </div>
  );
}

DashboardTileTotalBanks.propTypes = {
  title: PropTypes.string.isRequired,
  collectionPath: PropTypes.string.isRequired,
};

export default DashboardTileTotalBanks;