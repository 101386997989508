import axios from 'axios';
import Papa from 'papaparse';
import { db } from '../firebaseConfig';
import { collection, getDocs, doc, setDoc } from 'firebase/firestore';

const FIELDS = [
  "CERT", "RSSDID", "CBLRIND", "SC", "QBPRCOMLDESC", "ELNANTR", "ENDEFYMD", "NPERFV",
  "LNRESNCR", "CB", "REGAGNT", "NUMEMP", "ASSET", "NCREMULR", "NCRECONR", "STCNTY",
  "NALTOT", "IDLNCORR", "SPECGRPDESC", "SZLAUTO", "CITY", "P9ASSET", "STMULT",
  "P3ASSET", "DEPALLEX", "LIAB", "ITAX", "TRADE", "NIMY", "INSAGNT1", "DEPINS",
  "BKCLASS", "NOIJ", "NOIJY", "IDNCCIR", "EFFDATE", "LSAORE", "ELNATR", "NTRER",
  "INTINC", "NETINBM", "EQCSTKRX", "UC", "LSALNLS", "NTLNLS", "FLDOFF", "ERNASTR",
  "NCLNLSR", "LNLSNET", "DEPFOR", "NCRENRER", "PARCERT", "ROA", "P3LTOT", "RISDATE",
  "EQCDIV", "ROEINJR", "ROE", "SZLNHEL", "OCCDISTDESC", "EQ", "NAME", "HCTMULT",
  "SZLNCRCD", "NONIXAY", "SZLNOTH", "FREPO", "FDICDBSDESC", "CHBAL", "LSAOA",
  "OFFDOM", "SUBCHAPS", "ASDRRES", "NCRER", "AOAR", "ORE", "ACTIVE", "NTRECOSR",
  "NCRERESR", "DEPIDOM", "REPDTE", "REPYEAR", "IDNTCIR", "INTEXPY", "RBC1AAJ", "NTRERESR",
  "ASTEMPM", "AOA", "ROAPTX", "PTAXNETINC", "DEPDASTR", "NCCOMRER", "DEPBEFEX",
  "FEDDESC", "NONIIAY", "IDNTCONR", "ELNATRY", "NONIX", "LNLSNTV", "DEP", "CBSA",
  "IDNCOTHR", "NIM", "RBCRWAJ", "SZLNCI", "LSASCDBT", "IDNCGTPR", "NAMEFULL",
  "IDERNCVR", "NFAA", "ESTYMD", "NONII", "TRUST", "EQTOT", "ID", "CD1T3",
  "CD1T3R", "CD3LES", "CD3LESR", "CD3LESS", "CD3LESSR", "CDOV3", "CDOV3R",
  "CD3T12", "CD3T12R", "LNCI", "LNCI RATIO", "LIPMTG", "LNCON",
  "LNCRCD", "LNRE", "LNREMULT", "LNRELOC", "LNRENRES", "IGLSEC", "IGLSECR", "INSALL",
  "INSFDIC", "LIABR", "INTINCR", "INTINCY", "FDICDBS", "FDICSUPV", "COREDEP",
  "COREDEPR", "DRCON", "DRCONR", "DRCRCD", "DRCRCDR", "EQR", "VOLIAB",
  "VOLIABR", "IDT1CER", "IDT1RWAJR", "SCMUNIAA", "SCMUNIAF", "ESTINS",
  "IDDEPINR"
];

const FDIC_API_URL = 'https://banks.data.fdic.gov/api/financials';
const FED_RSSD_BATCH_SIZE = 5; // Process in batches of 5 FED_RSSDs

const getAllUniqueFEDRSSDs = async () => {
  const fedSet = new Set();
  const querySnapshot = await getDocs(collection(db, 'bank'));
  querySnapshot.forEach((docSnapshot) => {
    const data = docSnapshot.data();
    if (data.ID) {
      fedSet.add(data.ID.toString());
    }
  });
  return Array.from(fedSet);
};

const fetchLatestDataForBatch = async (fedBatch) => {
  try {
    const whereClause = fedBatch.map(fed => `ID=${fed}`).join(' OR ');
    const select = FIELDS.join(',');
    const url = `${FDIC_API_URL}?$select=${encodeURIComponent(select)}&$where=${encodeURIComponent(whereClause)}&$order=REPDTE DESC&$limit=1&format=json`;

    console.log('FDIC API Request URL:', decodeURIComponent(url));

    const response = await axios.get(url, { headers: { 'Accept': 'application/json' } });
    console.log('API Response Status:', response.status);

    if (!response.data?.data?.length) {
      console.warn(`No data returned for batch`);
      return {};
    }

    console.log('Fetched: ', response.data.data.length);
    console.log(response.data.data);
    console.log('-------------------------------');
    console.log('-------------------------------');
    console.log('-------------------------------');


    const latestDataMap = {};
    response.data.data.forEach(record => {
      const fed = record.data?.ID?.toString();
      if (fed && fedBatch.includes(fed)) {
        latestDataMap[fed] = record.data;
      }
    });

    return latestDataMap;
  } catch (error) {
    console.error('FDIC API Error:', {
      message: error.message,
      status: error.response?.status,
      statusText: error.response?.statusText,
      data: error.response?.data
    });
    return {};
  }
};

const saveDataToDB = async (fed, bankData) => {
  const bankDocRef = doc(db, 'bank', fed);
  const nonNullData = Object.fromEntries(
    Object.entries(bankData).filter(([, value]) => value !== null)
  );

  await setDoc(bankDocRef, nonNullData, { merge: true });
  console.log(`Saved data for FED_RSSD ${fed}`);
};

const fetchAllFEDRSSDs = async (addLogMessage) => {
  let totalBanksFetched = 0;
  const allBankDataForCSV = [];

  const log = (message) => {
    console.log(message);
    if (addLogMessage) addLogMessage(message);
  };

  try {
    log('Starting FDIC data fetch...');

    const allFEDs = await getAllUniqueFEDRSSDs();
    log(`Found ${allFEDs.length} unique FED_RSSDs in database`);

    if (allFEDs.length === 0) {
      log('No FED_RSSDs found in database. Exiting.');
      return 0;
    }

    const fedChunks = Array.from({ length: Math.ceil(allFEDs.length / FED_RSSD_BATCH_SIZE) }, (_, i) =>
      allFEDs.slice(i * FED_RSSD_BATCH_SIZE, (i + 1) * FED_RSSD_BATCH_SIZE)
    );

    for (let i = 0; i < fedChunks.length; i++) {
      const fedBatch = fedChunks[i];
      log(`Fetching data for FED_RSSD batch ${i + 1}/${fedChunks.length}`);

      const latestDataMap = await fetchLatestDataForBatch(fedBatch);

      for (const [fed, bankData] of Object.entries(latestDataMap)) {
        const extractedData = FIELDS.reduce((acc, field) => {
          acc[field] = bankData[field] ?? '';
          return acc;
        }, {});
        allBankDataForCSV.push(extractedData);
        await saveDataToDB(fed, bankData);
        totalBanksFetched += 1;
      }

      await new Promise(resolve => setTimeout(resolve, 2000)); // Rate limiting
    }

    log(`\nData fetch completed. Retrieved data for ${totalBanksFetched} banks`);

    if (allBankDataForCSV.length > 0) {
      const csv = Papa.unparse(allBankDataForCSV, { fields: FIELDS, quotes: true });
      const filename = `FDIC_Financials_${new Date().toISOString().split('T')[0]}.csv`;
      downloadCSV(csv, filename);
      log(`Generated CSV with ${allBankDataForCSV.length} records`);
    }

    return totalBanksFetched;
  } catch (error) {
    log(`Error during fetch: ${error.message}`);
    throw error;
  }
};

// Function to trigger CSV download in the browser
const downloadCSV = (csv, filename) => {
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

export default fetchAllFEDRSSDs;