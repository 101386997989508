import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import './target-count-modal.css';

const db = getFirestore();

function TargetBankCountModal({ closeModal, setLogMessages }) {
  const [minAsset, setMinAsset] = useState('');
  const [maxAsset, setMaxAsset] = useState('');
  const [error, setError] = useState(null);

  const handleSubmit = async () => {
    const minAssets = parseInt(minAsset) * 1000; // Convert millions to thousands
    const maxAssets = parseInt(maxAsset) * 1000; // Convert millions to thousands

    // Validation: Ensure min and max values are numbers and within a reasonable range
    if (isNaN(minAssets) || isNaN(maxAssets) || minAssets >= maxAssets) {
      setError('Please enter valid numeric values for both fields, with the minimum less than the maximum.');
      return;
    }
    setError(null);

    // Call the function to count banks with these thresholds
    try {
      const banksCollection = collection(db, 'bank');
      const assetsQuery = query(
        banksCollection,
        where('ASSET', '>=', minAssets),
        where('ASSET', '<=', maxAssets)
      );
      const snapshot = await getDocs(assetsQuery);
      const count = snapshot.size;

      setLogMessages((prevLogs) => [
        ...prevLogs,
        `Total banks with assets between $${minAsset}M and $${maxAsset}M: ${count}`
      ]);
      closeModal(); // Close the modal on success
    } catch (error) {
      setLogMessages((prevLogs) => [
        ...prevLogs,
        `Error counting banks: ${error.message}`
      ]);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Target Bank Count</h2>
        <p>Enter the asset thresholds (in Millions) to determine the number of banks in your target asset zone.</p>
        <input
          className="target-input-left"
          type="number"
          placeholder="Low Asset"
          value={minAsset}
          onChange={(e) => setMinAsset(e.target.value)}
        />
        <input
          className="target-input-right"
          type="number"
          placeholder="High Asset"
          value={maxAsset}
          onChange={(e) => setMaxAsset(e.target.value)}
        />
        {error && <p className="error-message">{error}</p>}
        <button className="target-submit" onClick={handleSubmit}>Submit</button>
        <button className="target-cancel"  onClick={closeModal}>Cancel</button>
      </div>
    </div>
  );
}

// Define PropTypes for the component
TargetBankCountModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  setLogMessages: PropTypes.func.isRequired
};

export default TargetBankCountModal;