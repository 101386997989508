const bankDataFields = [
  {
    groupName: "Core Institution Information",
    fields: [
      {
        key: "ID",
        title: "Bank Identification Number",
        unit: null,
        description: "Unique identifier assigned to each financial institution"
      },
      {
        key: "CERT",
        title: "FDIC Certificate Number",
        unit: "number",
        description: "A unique NUMBER assigned by the FDIC used to identify institutions"
      },
      {
        key: "RSSDID",
        title: "FDIC RSSD ID",
        unit: null,
        description: "Unique identifier assigned by Federal Reserve"
      },
      {
        key: "NAME",
        title: "Bank Name",
        unit: null,
        description: "Official name of the financial institution"
      },
      {
        key: "NAMEFULL",
        title: "Full Bank Name",
        unit: null,
        description: "Complete legal name of the bank"
      },
      {
        key: "NAMEHCR",
        title: "Holding Company Name",
        unit: null,
        description: "Name of regulatory top holder"
      },
      {
        key: "PRIORNAME1",
        title: "Prior Bank Name 1",
        unit: null,
        description: null
      },
      {
        key: "PRIORNAME2",
        title: "Prior Bank Name 2",
        unit: null,
        description: null
      },
      {
        key: "ADDRESS",
        title: "Address",
        unit: null,
        description: "Primary physical address"
      },
      {
        key: "ADDRESS2",
        title: "Additional Address",
        unit: null,
        description: "Secondary address information"
      },
      {
        key: "CITY",
        title: "City",
        unit: null,
        description: "City location"
      },
      {
        key: "CITYHCR",
        title: "Holding Company City",
        unit: null,
        description: "City of holding company"
      },
      {
        key: "COUNTY",
        title: "County",
        unit: null,
        description: null
      },
      {
        key: "STNAME",
        title: "State Name",
        unit: null,
        description: null
      },
      {
        key: "STALP",
        title: "State Alpha Code",
        unit: null,
        description: null
      },
      {
        key: "STCNTY",
        title: "State County",
        unit: null,
        description: null
      },
      {
        key: "ZIP",
        title: "ZIP Code",
        unit: null,
        description: null
      },
      {
        key: "WEBADDR",
        title: "Website Address",
        unit: null,
        description: null
      },
      {
        key: "UC",
        title: "Unit Code",
        unit: "string",
        description: "Unique unit identifier code"
      },
    ]
  },
  {
    groupName: "Status and Classification",
    fields: [
      {
        key: "ACTIVE",
        title: "Active Status",
        unit: null,
        description: "Active Status Indicator"
      },
      {
        key: "INACTIVE",
        title: "Inactive Status",
        unit: null,
        description: "Inactive Status Indicator"
      },
      {
        key: "DENOVO",
        title: "Denovo Institution",
        unit: null,
        description: "New institution indicator"
      },
      {
        key: "NEWCERT",
        title: "New Certificate",
        unit: null,
        description: null
      },
      {
        key: "BKCLASS",
        title: "Bank Class",
        unit: null,
        description: null
      },
      {
        key: "CB",
        title: "Community Bank Status",
        unit: null,
        description: null
      },
      {
        key: "MUTUAL",
        title: "Mutual Institution",
        unit: null,
        description: null
      }
    ]
  },
  {
    groupName: "Geographic and Statistical Areas",
    fields: [
      {
        key: "LATITUDE",
        title: "Latitude",
        unit: null,
        description: null
      },
      {
        key: "LONGITUDE",
        title: "Longitude",
        unit: null,
        description: null
      },
      {
        key: "MSA",
        title: "Metropolitan Statistical Area",
        unit: null,
        description: null
      },
      {
        key: "MSA_NO",
        title: "MSA Number",
        unit: null,
        description: null
      },
      {
        key: "CMSA",
        title: "Consolidated MSA",
        unit: null,
        description: null
      },
      {
        key: "CMSA_NO",
        title: "CMSA Number",
        unit: null,
        description: null
      },
      {
        key: "CBSA",
        title: "Core Based Statistical Area",
        unit: null,
        description: null
      },
      {
        key: "CBSA_NO",
        title: "CBSA Number",
        unit: null,
        description: null
      },
      {
        key: "CBSA_DIV",
        title: "CBSA Division",
        unit: null,
        description: null
      },
      {
        key: "CBSA_DIV_NO",
        title: "CBSA Division Number",
        unit: null,
        description: null
      },
      {
        key: "CBSA_DIV_FLG",
        title: "CBSA Division Flag",
        unit: null,
        description: null
      },
      {
        key: "CBSA_METRO",
        title: "CBSA Metro",
        unit: null,
        description: null
      },
      {
        key: "CBSA_METRO_FLG",
        title: "CBSA Metro Flag",
        unit: null,
        description: null
      },
      {
        key: "CBSA_MICRO_FLG",
        title: "CBSA Micro Flag",
        unit: null,
        description: null
      },
      {
        key: "CSA",
        title: "Combined Statistical Area",
        unit: null,
        description: null
      },
      {
        key: "CSA_NO",
        title: "CSA Number",
        unit: null,
        description: null
      },
      {
        key: "CSA_FLG",
        title: "CSA Flag",
        unit: null,
        description: null
      },
      {
        key: "TRACT",
        title: "Census Tract",
        unit: null,
        description: null
      }
    ]
  },
  {
    groupName: "Financial Position",
    fields: [
      {
        key: "ASSET",
        title: "Total Assets",
        unit: "Thousands of US Dollars",
        description: "Sum of all assets"
      },
      {
        key: "ASTEMPM",
        title: "Assets per Employee",
        unit: null,
        description: null
      },
      {
        key: "LIAB",
        title: "Total Liabilities",
        unit: "Thousands of US Dollars",
        description: "Total liabilities"
      },
      {
        key: "LIABR",
        title: "Liabilities Ratio",
        unit: null,
        description: null
      },
      {
        key: "EQ",
        title: "Equity",
        unit: null,
        description: null
      },
      {
        key: "EQTOT",
        title: "Total Equity",
        unit: null,
        description: null
      },
      {
        key: "EQR",
        title: "Equity Ratio",
        unit: null,
        description: null
      },
      {
        key: "EQCDIV",
        title: "Equity Cash Dividends",
        unit: null,
        description: null
      },
      {
        key: "EQCSTKRX",
        title: "Equity Common Stock Ratio",
        unit: null,
        description: null
      }
    ]
  },
  {
    groupName: "Deposit Information",
    fields: [
      {
        key: "DEP",
        title: "Total Deposits",
        unit: "Thousands of US Dollars",
        description: "Total deposits"
      },
      {
        key: "DEPDOM",
        title: "Domestic Deposits",
        unit: "Thousands of US Dollars",
        description: "Domestic office deposits"
      },
      {
        key: "DEPIDOM",
        title: "Insured Domestic Deposits",
        unit: null,
        description: null
      },
      {
        key: "DEPINS",
        title: "Insured Deposits",
        unit: null,
        description: null
      },
      {
        key: "DEPDASTR",
        title: "Deposit Asset Ratio",
        unit: null,
        description: null
      },
      {
        key: "DEPBEFEX",
        title: "Deposits Before Exclusions",
        unit: null,
        description: null
      },
      {
        key: "DEPALLEX",
        title: "Deposit Allowance Exclusions",
        unit: null,
        description: null
      },
      {
        key: "COREDEP",
        title: "Core Deposits",
        unit: null,
        description: null
      },
      {
        key: "COREDEPR",
        title: "Core Deposits Ratio",
        unit: null,
        description: null
      }
    ]
  },
  {
    groupName: "Certificate Deposits",
    fields: [
      {
        key: "CD1T3",
        title: "CDs $100K-$250K",
        unit: null,
        description: null
      },
      {
        key: "CD1T3R",
        title: "CDs $100K-$250K Ratio",
        unit: null,
        description: null
      },
      {
        key: "CD3LES",
        title: "CDs Less than $100K",
        unit: null,
        description: null
      },
      {
        key: "CD3LESR",
        title: "CDs Less than $100K Ratio",
        unit: null,
        description: null
      },
      {
        key: "CD3LESS",
        title: "CDs Less than $250K",
        unit: null,
        description: null
      },
      {
        key: "CD3LESSR",
        title: "CDs Less than $250K Ratio",
        unit: null,
        description: null
      },
      {
        key: "CD3T12",
        title: "CDs $250K-$500K",
        unit: null,
        description: null
      },
      {
        key: "CD3T12R",
        title: "CDs $250K-$500K Ratio",
        unit: null,
        description: null
      },
      {
        key: "CDOV3",
        title: "CDs Over $500K",
        unit: null,
        description: null
      },
      {
        key: "CDOV3R",
        title: "CDs Over $500K Ratio",
        unit: null,
        description: null
      }
    ]
  },
  {
    groupName: "Insurance Status",
    fields: [
      {
        key: "INSAGNT1",
        title: "Primary Insurance Agent",
        unit: null,
        description: null
      },
      {
        key: "INSAGNT2",
        title: "Secondary Insurance Agent",
        unit: null,
        description: null
      },
      {
        key: "INSALL",
        title: "Insurance All Types",
        unit: null,
        description: null
      },
      {
        key: "INSBIF",
        title: "Bank Insurance Fund Status",
        unit: null,
        description: null
      },
      {
        key: "INSCOML",
        title: "Commercial Insurance Status",
        unit: null,
        description: null
      },
      {
        key: "INSDATE",
        title: "Insurance Start Date",
        unit: null,
        description: null
      },
      {
        key: "INSDIF",
        title: "Deposit Insurance Fund Status",
        unit: null,
        description: null
      },
      {
        key: "INSFDIC",
        title: "FDIC Insurance Status",
        unit: null,
        description: null
      },
      {
        key: "INSSAIF",
        title: "Savings Association Insurance Fund",
        unit: null,
        description: null
      },
      {
        key: "INSSAVE",
        title: "Savings Insurance Status",
        unit: null,
        description: null
      },
      {
        key: "INSTAG",
        title: "Insurance TAG Status",
        unit: null,
        description: null
      },
      {
        key: "INSTCRCD",
        title: "Insurance Credit Card Status",
        unit: null,
        description: null
      },
      {
        key: "ESTINS",
        title: "Establishment Insurance Date",
        unit: null,
        description: null
      }
    ]
  },
  {
    groupName: "Performance Metrics",
    fields: [
      {
        key: "ROA",
        title: "Return on Assets",
        unit: "number",
        description: "Return on Assets"
      },
      {
        key: "ROAQ",
        title: "Quarterly ROA",
        unit: "number",
        description: "Quarterly Return on Assets"
      },
      {
        key: "ROAPTX",
        title: "Pretax ROA",
        unit: "number",
        description: "Pretax Return on Assets"
      },
      {
        key: "ROAPTXQ",
        title: "Quarterly Pretax ROA",
        unit: "number",
        description: "Quarterly Pretax Return on Assets"
      },
      {
        key: "ROE",
        title: "Return on Equity",
        unit: "number",
        description: "Return on Equity"
      },
      {
        key: "ROEQ",
        title: "Quarterly ROE",
        unit: "number",
        description: "Quarterly Return on Equity"
      },
      {
        key: "ROEINJR",
        title: "ROE Injected Ratio",
        unit: null,
        description: null
      },
      {
        key: "ERNASTR",
        title: "Earnings Asset Ratio",
        unit: null,
        description: null
      }
    ]
  },
  {
    groupName: "Income Information",
    fields: [
      {
        key: "NETINC",
        title: "Net Income",
        unit: "Thousands of US Dollars",
        description: "Net Income"
      },
      {
        key: "NETINCQ",
        title: "Quarterly Net Income",
        unit: "Thousands of US Dollars",
        description: "Quarterly Net Income"
      },
      {
        key: "NETINBM",
        title: "Net Income Before Minority Interest",
        unit: null,
        description: null
      },
      {
        key: "PTAXNETINC",
        title: "Pre-tax Net Income",
        unit: null,
        description: null
      },
      {
        key: "INTINC",
        title: "Interest Income",
        unit: "Thousands of US Dollars",
        description: "Total Interest Income"
      },
      {
        key: "INTINCR",
        title: "Interest Income Ratio",
        unit: null,
        description: null
      },
      {
        key: "INTINCY",
        title: "Interest Income Yield",
        unit: null,
        description: null
      },
      {
        key: "INTEXPY",
        title: "Interest Expense Yield",
        unit: null,
        description: null
      },
      {
        key: "NIM",
        title: "Net Interest Margin",
        unit: null,
        description: null
      },
      {
        key: "NIMY",
        title: "Net Interest Margin Yield",
        unit: null,
        description: null
      },
      {
        key: "IDDEPINR",
        title: "Insured Deposits Insurance Rate",
        unit: "ratio",
        description: "Rate of insurance on deposits"
      },
      {
        key: "IDERNCVR",
        title: "Income Earnings Coverage Ratio",
        unit: "ratio",
        description: "Earnings coverage ratio for income"
      },
      {
        key: "IDLNCORR",
        title: "Income Loan Correlation Ratio",
        unit: "ratio",
        description: "Correlation ratio between income and loans"
      },
      {
        key: "IDNCCIR",
        title: "Income Non-current Commercial & Industrial Ratio",
        unit: "ratio",
        description: "Ratio of non-current commercial & industrial loans to income"
      },
      {
        key: "IDNCGTPR",
        title: "Income Non-current Government Program Ratio",
        unit: "ratio",
        description: "Ratio of non-current government program loans to income"
      },
      {
        key: "IDNCOTHR",
        title: "Income Non-current Other Ratio",
        unit: "ratio",
        description: "Ratio of other non-current loans to income"
      },
      {
        key: "IDNTCIR",
        title: "Income Notice Commercial & Industrial Ratio",
        unit: "ratio",
        description: "Ratio of commercial & industrial loans in notice status"
      },
      {
        key: "IDNTCONR",
        title: "Income Notice Consumer Ratio",
        unit: "ratio",
        description: "Ratio of consumer loans in notice status"
      }
    ]
  },
  {
    groupName: "Non-Interest Income",
    fields: [
      {
        key: "NONII",
        title: "Non-interest Income",
        unit: "Thousands of US Dollars",
        description: "Total Non-interest Income"
      },
      {
        key: "NONIIAY",
        title: "Non-interest Income Annual Yield",
        unit: null,
        description: null
      },
      {
        key: "NONIX",
        title: "Non-interest Expense",
        unit: "Thousands of US Dollars",
        description: "Total Non-interest Expense"
      },
      {
        key: "NONIXAY",
        title: "Non-interest Expense Annual Yield",
        unit: null,
        description: null
      },
      {
        key: "NOIJ",
        title: "Net Operating Income",
        unit: null,
        description: null
      },
      {
        key: "NOIJY",
        title: "Net Operating Income Yield",
        unit: null,
        description: null
      },
      {
        key: "ITAX",
        title: "Income Tax",
        unit: "Thousands of US Dollars",
        description: "Applicable income taxes"
      }
    ]
  },
  {
    groupName: "Assets and Securities",
    fields: [
      {
        key: "SC",
        title: "Securities Total",
        unit: null,
        description: null
      },
      {
        key: "SCMUNIAA",
        title: "Securities Municipal Available Alternative",
        unit: null,
        description: null
      },
      {
        key: "SCMUNIAF",
        title: "Securities Municipal Available Full",
        unit: null,
        description: null
      },
      {
        key: "TRADE",
        title: "Trading Account Assets",
        unit: null,
        description: null
      },
      {
        key: "IGLSEC",
        title: "Investment Grade Level Securities",
        unit: null,
        description: null
      },
      {
        key: "IGLSECR",
        title: "Investment Grade Level Securities Ratio",
        unit: null,
        description: null
      },
      {
        key: "AOA",
        title: "All Other Assets",
        unit: null,
        description: null
      },
      {
        key: "AOAR",
        title: "All Other Assets Ratio",
        unit: null,
        description: null
      },
      {
        key: "BKPREM",
        title: "Bank Premises",
        unit: null,
        description: null
      },
      {
        key: "FREPO",
        title: "Federal Funds Sold",
        unit: "Thousands of US Dollars",
        description: "Federal Funds Sold and Securities Purchased Under Agreements To Resell"
      },
      {
        key: "CHBAL",
        title: "Cash & Due from Depository Institutions",
        unit: "Thousands of US Dollars",
        description: "Total Cash and Balances Due From Depository Institutions"
      }
    ]
  },
  {
    groupName: "Loan Portfolio",
    fields: [
      {
        key: "LNLSNET",
        title: "Net Loans and Leases",
        unit: "Thousands of US Dollars",
        description: "Net Loans and Leases"
      },
      {
        key: "LNRE",
        title: "Real Estate Loans",
        unit: "Thousands of US Dollars",
        description: "Total Real Estate Loans"
      },
      {
        key: "LNRELOC",
        title: "Real Estate Lines of Credit",
        unit: null,
        description: null
      },
      {
        key: "LNREMULT",
        title: "Real Estate Multifamily",
        unit: "Thousands of US Dollars",
        description: "Multifamily Real Estate Loans"
      },
      {
        key: "LNRENRES",
        title: "Real Estate Non-residential",
        unit: "Thousands of US Dollars",
        description: "Non-residential Real Estate Loans"
      },
      {
        key: "LNRESNCR",
        title: "Real Estate Non-current Ratio",
        unit: null,
        description: null
      },
      {
        key: "LNCI",
        title: "Commercial and Industrial Loans",
        unit: "Thousands of US Dollars",
        description: "Commercial and Industrial Loans"
      },
      {
        key: "LNCON",
        title: "Consumer Loans",
        unit: "Thousands of US Dollars",
        description: "Consumer Loans"
      },
      {
        key: "LNCRCD",
        title: "Credit Card Loans",
        unit: "Thousands of US Dollars",
        description: "Credit Card Loans"
      },
      {
        key: "LNLSNTV",
        title: "Net Loans to Total Assets",
        unit: null,
        description: null
      },
      {
        key: "ELNANTR",
        title: "Early Loan Analysis Net TR",
        unit: "ratio",
        description: "Early loan analysis net transaction ratio"
      },
      {
        key: "ELNATR",
        title: "Early Loan Analysis TR",
        unit: "ratio",
        description: "Early loan analysis transaction ratio"
      },
      {
        key: "ELNATRY",
        title: "Early Loan Analysis TR Yield",
        unit: "ratio",
        description: "Early loan analysis transaction yield"
      }
    ]
  },
  {
    groupName: "Asset Quality",
    fields: [
      {
        key: "NCLNLSR",
        title: "Non-current Loans and Leases Ratio",
        unit: null,
        description: null
      },
      {
        key: "NCREMULR",
        title: "Non-current RE Multifamily Ratio",
        unit: null,
        description: null
      },
      {
        key: "NCRECONR",
        title: "Non-current RE Construction Ratio",
        unit: null,
        description: null
      },
      {
        key: "NCRENRER",
        title: "Non-current RE Non-residential Ratio",
        unit: null,
        description: null
      },
      {
        key: "NCRER",
        title: "Non-current RE Ratio",
        unit: null,
        description: null
      },
      {
        key: "NCRERESR",
        title: "Non-current RE Residential Ratio",
        unit: null,
        description: null
      },
      {
        key: "NTLNLS",
        title: "Net Loan Losses",
        unit: null,
        description: null
      },
      {
        key: "NTRECOSR",
        title: "Net RE Construction Loss Ratio",
        unit: null,
        description: null
      },
      {
        key: "NTRER",
        title: "Net RE Loss Ratio",
        unit: null,
        description: null
      },
      {
        key: "NTRERESR",
        title: "Net RE Residential Loss Ratio",
        unit: null,
        description: null
      },
      {
        key: "NCLNLS",
        title: "Non-current Loans and Leases",
        unit: "Thousands of US Dollars",
        description: "Total non-current loans and lease financing receivables"
      },
      {
        key: "NCCOMRER",
        title: "Non-current Commercial Real Estate Ratio",
        unit: "ratio",
        description: "Ratio of non-current commercial real estate loans"
      },
      {
        key: "NFAA",
        title: "Non-Financial Assets Amount",
        unit: "Thousands of US Dollars",
        description: "Total non-financial assets held"
      }
    ]
  },
  {
    groupName: "Risk Measures",
    fields: [
      {
        key: "VOLIAB",
        title: "Volatile Liabilities",
        unit: null,
        description: null
      },
      {
        key: "VOLIABR",
        title: "Volatile Liabilities Ratio",
        unit: null,
        description: null
      },
      {
        key: "NPERFV",
        title: "Non-Performing Asset Fair Value",
        unit: null,
        description: null
      },
      {
        key: "P3ASSET",
        title: "Past Due 30-89 Days Assets",
        unit: null,
        description: null
      },
      {
        key: "P9ASSET",
        title: "Past Due 90+ Days Assets",
        unit: null,
        description: null
      },
      {
        key: "ASDRRES",
        title: "Asset Duration Reserve",
        unit: null,
        description: null
      },
      {
        key: "IDT1CER",
        title: "Insurance Tier 1 Capital Ratio",
        unit: "ratio",
        description: "Tier 1 capital ratio for insurance purposes"
      },
      {
        key: "IDT1RWAJR",
        title: "Insurance Tier 1 RWA Ratio",
        unit: "ratio",
        description: "Risk-weighted assets ratio for Tier 1 capital"
      },
      {
        key: "DRCRCDR",
        title: "Direct Credit Card Deposits Ratio",
        unit: "ratio",
        description: "Ratio of direct credit card deposits to total deposits"
      }
    ]
  },
  {
    groupName: "Regulatory Information",
    fields: [
      {
        key: "REGAGNT",
        title: "Primary Federal Regulator",
        unit: null,
        description: null
      },
      {
        key: "REGAGENT2",
        title: "Secondary Federal Regulator",
        unit: null,
        description: null
      },
      {
        key: "FDICDBS",
        title: "FDIC Database Status",
        unit: null,
        description: null
      },
      {
        key: "FDICDBSDESC",
        title: "FDIC Database Description",
        unit: null,
        description: null
      },
      {
        key: "FDICSUPV",
        title: "FDIC Supervision Type",
        unit: null,
        description: null
      },
      {
        key: "FDICREGN",
        title: "FDIC Region",
        unit: null,
        description: null
      },
      {
        key: "FED",
        title: "Federal Reserve District",
        unit: null,
        description: null
      },
      {
        key: "FEDDESC",
        title: "Federal Reserve Description",
        unit: null,
        description: null
      },
      {
        key: "SUPRV_FD",
        title: "Supervisory Federal District",
        unit: "string",
        description: "Federal Reserve supervisory district code"
      },
      {
        key: "OTSDIST",
        title: "OTS District",
        unit: null,
        description: null
      },
      {
        key: "OTSREGNM",
        title: "OTS Region Name",
        unit: null,
        description: null
      },
      {
        key: "CFPBEFFDTE",
        title: "CFPB Oversight Effective Date",
        unit: "date",
        description: "Date when Consumer Financial Protection Bureau oversight began"
      },
      {
        key: "CFPBENDDTE",
        title: "CFPB Oversight End Date",
        unit: "date",
        description: "Date when Consumer Financial Protection Bureau oversight ended"
      },
      {
        key: "CFPBFLAG",
        title: "CFPB Oversight Flag",
        unit: "number",
        description: "Indicates if institution is under CFPB oversight (0=No, 1=Yes)"
      },
      {
        key: "QBPRCOML",
        title: "Quarterly Banking Profile Commercial Level",
        unit: "string",
        description: "Commercial lending level indicator for Quarterly Banking Profile"
      },
      {
        key: "QBPRCOMLDESC",
        title: "Quarterly Banking Profile Commercial Description",
        unit: "string",
        description: "Description of commercial lending category in QBP"
      }
    ]
  },
  {
    groupName: "Charter and Status",
    fields: [
      {
        key: "CHARTER",
        title: "Charter Type",
        unit: null,
        description: null
      },
      {
        key: "CHRTAGNT",
        title: "Charter Agent",
        unit: null,
        description: null
      },
      {
        key: "FEDCHRTR",
        title: "Federal Charter Status",
        unit: null,
        description: null
      },
      {
        key: "STCHRTR",
        title: "State Charter Status",
        unit: null,
        description: null
      },
      {
        key: "CONSERVE",
        title: "Conservatorship Status",
        unit: null,
        description: null
      },
      {
        key: "SASSER",
        title: "Sasser Status",
        unit: null,
        description: null
      },
      {
        key: "LAW_SASSER_FLG",
        title: "Law Sasser Flag",
        unit: null,
        description: null
      }
    ]
  },
  {
    groupName: "Bank Size Classifications",
    fields: [
      {
        key: "SZ100",
        title: "Size Class 100",
        unit: null,
        description: null
      },
      {
        key: "SZ100T1B",
        title: "Size Class 100M-1B",
        unit: null,
        description: null
      },
      {
        key: "SZ1BT10B",
        title: "Size Class 1B-10B",
        unit: null,
        description: null
      },
      {
        key: "SZ250BP",
        title: "Size Class 250B Plus",
        unit: null,
        description: null
      },
      {
        key: "S10T250B",
        title: "Size Class $10M-$250B",
        unit: "number",
        description: "Institution size classification indicator"
      },
      {
        key: "SZLAUTO",
        title: "Size Auto Loans",
        unit: null,
        description: null
      },
      {
        key: "SZLNCI",
        title: "Size Commercial & Industrial Loans",
        unit: null,
        description: null
      },
      {
        key: "SZLNCRCD",
        title: "Size Credit Card Loans",
        unit: null,
        description: null
      },
      {
        key: "SZLNHEL",
        title: "Size Home Equity Loans",
        unit: null,
        description: null
      },
      {
        key: "SZLNOTH",
        title: "Size Other Loans",
        unit: null,
        description: null
      },
      {
        key: "SPECGRP",
        title: "Special Group",
        unit: "number",
        description: "Special institutional group classification code"
      },
      {
        key: "SPECGRPN",
        title: "Special Group Name",
        unit: "string",
        description: "Name of special institutional group"
      },
      {
        key: "SPECGRPDESC",
        title: "Special Group Description",
        unit: null,
        description: null
      },
      {
        key: "STNUM",
        title: "State Number",
        unit: "string",
        description: "State numerical code"
      },
      {
        key: "STMULT",
        title: "State Multiple",
        unit: "string",
        description: "Multiple state operations indicator"
      }
    ]
  },
  {
    groupName: "Web URLs and Trade Names",
    fields: [
      {
        key: "TE01N528",
        title: "Web Site URL 01",
        unit: null,
        description: null
      },
      {
        key: "TE01N529",
        title: "Trade Name 01",
        unit: null,
        description: null
      },
      {
        key: "TE02N528",
        title: "Web Site URL 02",
        unit: null,
        description: null
      },
      {
        key: "TE02N529",
        title: "Trade Name 02",
        unit: null,
        description: null
      },
      {
        key: "TE03N528",
        title: "Web Site URL 03",
        unit: null,
        description: null
      },
      {
        key: "TE03N529",
        title: "Trade Name 03",
        unit: null,
        description: null
      },
      {
        key: "TE04N528",
        title: "Web Site URL 04",
        unit: null,
        description: null
      },
      {
        key: "TE04N529",
        title: "Trade Name 04",
        unit: null,
        description: null
      },
      {
        key: "TE05N528",
        title: "Web Site URL 05",
        unit: null,
        description: null
      },
      {
        key: "TE05N529",
        title: "Trade Name 05",
        unit: null,
        description: null
      },
      {
        key: "TE06N528",
        title: "Web Site URL 06",
        unit: null,
        description: null
      },
      {
        key: "TE06N529",
        title: "Trade Name 06",
        unit: null,
        description: null
      },
      {
        key: "TE07N528",
        title: "Web Site URL 07",
        unit: null,
        description: null
      },
      {
        key: "TE08N528",
        title: "Web Site URL 08",
        unit: null,
        description: null
      },
      {
        key: "TE09N528",
        title: "Web Site URL 09",
        unit: null,
        description: null
      },
      {
        key: "TE10N528",
        title: "Web Site URL 10",
        unit: null,
        description: null
      }
    ]
  },
  {
    groupName: "Operations",
    fields: [
      {
        key: "NUMEMP",
        title: "Number of Employees",
        unit: null,
        description: null
      },
      {
        key: "OFFICES",
        title: "Total Offices",
        unit: null,
        description: null
      },
      {
        key: "OFFDOM",
        title: "Domestic Offices",
        unit: null,
        description: null
      },
      {
        key: "OFFFOR",
        title: "Foreign Offices",
        unit: null,
        description: null
      },
      {
        key: "OFFOA",
        title: "Other Offices",
        unit: null,
        description: null
      },
      {
        key: "FLDOFF",
        title: "Field Office",
        unit: null,
        description: null
      }
    ]
  },
  {
    groupName: "Dates and Reporting",
    fields: [
      {
        key: "REPDTE",
        title: "Report Date",
        unit: null,
        description: null
      },
      {
        key: "REPYEAR",
        title: "Report Year",
        unit: null,
        description: null
      },
      {
        key: "RISDATE",
        title: "Risk Date",
        unit: null,
        description: null
      },
      {
        key: "EFFDATE",
        title: "Effective Date",
        unit: null,
        description: null
      },
      {
        key: "ENDEFYMD",
        title: "End Effective Year Month Day",
        unit: null,
        description: null
      },
      {
        key: "ESTYMD",
        title: "Establishment Date",
        unit: null,
        description: null
      },
      {
        key: "RUNDATE",
        title: "Run Date",
        unit: null,
        description: null
      },
      {
        key: "CALLFORM",
        title: "Call Report Form",
        unit: null,
        description: null
      }
    ]
  },
  {
    groupName: "Additional Identifiers",
    fields: [
      {
        key: "ULTCERT",
        title: "Ultimate Certificate Number",
        unit: null,
        description: null
      },
      {
        key: "PARCERT",
        title: "Parent Certificate Number",
        unit: null,
        description: null
      },
      {
        key: "UNINUM",
        title: "Unique Institution Number",
        unit: null,
        description: null
      },
      {
        key: "DOCKET",
        title: "Docket Number",
        unit: null,
        description: null
      },
      {
        key: "FED_RSSD",
        title: "Federal Reserve RSSD ID",
        unit: null,
        description: null
      },
      {
        key: "RSSDHCR",
        title: "RSSD Holding Company Reporter",
        unit: null,
        description: null
      },
      {
        key: "FORM31",
        title: "Form 31 Indicator",
        unit: null,
        description: null
      },
    ]
  },
  {
    groupName: "Additional Status Indicators",
    fields: [
      {
        key: "OAKAR",
        title: "OAKAR Status",
        unit: null,
        description: null
      },
      {
        key: "ORE",
        title: "ORE Status",
        unit: null,
        description: null
      },
      {
        key: "SUBCHAPS",
        title: "Subchapter S Status",
        unit: null,
        description: null
      },
      {
        key: "TRUST",
        title: "Trust Powers",
        unit: null,
        description: null
      },
      {
        key: "CLCODE",
        title: "Closure Code",
        unit: null,
        description: null
      },
      {
        key: "MDI_STATUS_CODE",
        title: "Minority Depository Institution Status Code",
        unit: null,
        description: null
      },
      {
        key: "MDI_STATUS_DESC",
        title: "Minority Depository Institution Status Description",
        unit: null,
        description: null
      },
      {
        key: "HCTMULT",
        title: "Holding Company Multiple Status",
        unit: "string",
        description: "Indicates if part of multiple-bank holding company"
      },
      {
        key: "IBA",
        title: "International Banking Activity",
        unit: "number",
        description: "Flag for international banking activities"
      },
      {
        key: "OCCDIST",
        title: "OCC District",
        unit: "string",
        description: "Office of the Comptroller of the Currency district"
      },
      {
        key: "OI",
        title: "Other Institution Indicator",
        unit: "number",
        description: "Flag for other institution classification"
      }
    ]
  }
];

export default bankDataFields;

