/*ADDRESS	BKCLASS	CBSA	CBSA_DIV	CBSA_DIV_FLG	CBSA_DIV_NO	CBSA_METRO	CBSA_METRO_FLG	CBSA_METRO_NAME	CBSA_MICRO_FLG	CBSA_NO	CERT	CITY	COUNTY	CSA	CSA_FLG	CSA_NO	ESTYMD	FI_UNINUM	MAINOFF	NAME	OFFNAME	OFFNUM	RUNDATE	SERVTYPE	STALP	STCNTY	STNAME	UNINUM	ZIP*/
import axios from 'axios';
import Papa from 'papaparse';
import { db } from '../firebaseConfig';
import { collection, getDocs, doc, setDoc } from 'firebase/firestore';

const FIELDS = [
  "ADDRESS", "BKCLASS", "CBSA", "CBSA_DIV", "CBSA_DIV_FLG", "CBSA_DIV_NO", "CBSA_METRO", 
  "CBSA_METRO_FLG", "CBSA_METRO_NAME", "CBSA_MICRO_FLG", "CBSA_NO", "CERT", "CITY", 
  "COUNTY", "CSA", "CSA_FLG", "CSA_NO", "ESTYMD", "FI_UNINUM", "MAINOFF", "NAME", 
  "OFFNAME", "OFFNUM", "RUNDATE", "SERVTYPE", "STALP", "STCNTY", "STNAME", "UNINUM", 
  "ZIP"
];

const FDIC_API_URL = 'https://banks.data.fdic.gov/api/locations';
const CERT_BATCH_SIZE = 50; // Process in batches of 50 CERTs

const getAllUniqueCERTs = async () => {
  const certSet = new Set();
  const querySnapshot = await getDocs(collection(db, 'bank'));
  querySnapshot.forEach((docSnapshot) => {
    const data = docSnapshot.data();
    if (data.CERT) {
      certSet.add(data.CERT.toString());
    }
  });
  return Array.from(certSet);
};

const fetchLatestDataForBatch = async (certBatch) => {
  try {
    const whereClause = certBatch.map(cert => `CERT=${cert}`).join(' OR ');
    const select = FIELDS.join(',');
    const url = `${FDIC_API_URL}?$select=${encodeURIComponent(select)}&$where=${encodeURIComponent(whereClause)}&$order=REPDTE DESC&$limit=1&format=json`;

    console.log('FDIC API Request URL:', decodeURIComponent(url));

    const response = await axios.get(url, { headers: { 'Accept': 'application/json' } });
    console.log('API Response Status:', response.status);

    if (!response.data?.data?.length) {
      console.warn(`No data returned for batch`);
      return {};
    }

    const latestDataMap = {};
    response.data.data.forEach(record => {
      const cert = record.data?.CERT?.toString();
      if (cert && certBatch.includes(cert)) {
        latestDataMap[cert] = record.data;
      }
    });

    return latestDataMap;
  } catch (error) {
    console.error('FDIC API Error:', {
      message: error.message,
      status: error.response?.status,
      statusText: error.response?.statusText,
      data: error.response?.data
    });
    return {};
  }
};

const saveDataToDB = async (cert, bankData) => {
  const bankDocRef = doc(db, 'bank', cert);
  const nonNullData = Object.fromEntries(
    Object.entries(bankData).filter(([, value]) => value !== null)
  );

  await setDoc(bankDocRef, nonNullData, { merge: true });
  //console.log(`Saved data for CERT ${cert}`);
};

const fetchAllLocations = async (addLogMessage) => {
  let totalBanksFetched = 0;
  const allBankDataForCSV = [];

  const log = (message) => {
    console.log(message);
    if (addLogMessage) addLogMessage(message);
  };

  try {
    log('Starting FDIC data fetch...');

    const allCERTs = await getAllUniqueCERTs();
    log(`Found ${allCERTs.length} unique CERTs in database`);

    if (allCERTs.length === 0) {
      log('No CERTs found in database. Exiting.');
      return 0;
    }

    const certChunks = Array.from({ length: Math.ceil(allCERTs.length / CERT_BATCH_SIZE) }, (_, i) =>
      allCERTs.slice(i * CERT_BATCH_SIZE, (i + 1) * CERT_BATCH_SIZE)
    );

    for (let i = 0; i < certChunks.length; i++) {
      const certBatch = certChunks[i];
      log(`Fetching data for CERT batch ${i + 1}/${certChunks.length}`);

      const latestDataMap = await fetchLatestDataForBatch(certBatch);

      for (const [cert, bankData] of Object.entries(latestDataMap)) {
        const extractedData = FIELDS.reduce((acc, field) => {
          acc[field] = bankData[field] ?? '';
          return acc;
        }, {});
        allBankDataForCSV.push(extractedData);
        await saveDataToDB(cert, bankData);
        totalBanksFetched += 1;
      }

      await new Promise(resolve => setTimeout(resolve, 2000)); // Rate limiting
    }

    log(`\nData fetch completed. Retrieved data for ${totalBanksFetched} banks`);

    if (allBankDataForCSV.length > 0) {
      const csv = Papa.unparse(allBankDataForCSV, { fields: FIELDS, quotes: true });
      const filename = `FDIC_Financials_${new Date().toISOString().split('T')[0]}.csv`;
      downloadCSV(csv, filename);
      log(`Generated CSV with ${allBankDataForCSV.length} records`);
    }

    return totalBanksFetched;
  } catch (error) {
    log(`Error during fetch: ${error.message}`);
    throw error;
  }
};

// Function to trigger CSV download in the browser
const downloadCSV = (csv, filename) => {
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

export default fetchAllLocations;