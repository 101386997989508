// DashboardTile.js
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getFirestore, collection, getDocs, query, where } from 'firebase/firestore';
import './dashboard-tile.css';

function DashboardTileAssetRange({ title, collectionPath }) {
  const [data, setData] = useState(null);

  useEffect(() => {
    // Function to fetch data from the specified Firestore collection
    const fetchData = async () => {

      const minAssets = 400 * 1000; // Convert millions to thousands
      const maxAssets = 12000 * 1000; // Convert millions to thousands

      try {
        const db = getFirestore(); // Initialize Firestore
        const banksCollection = collection(db, 'bank');
        const assetsQuery = query(
          banksCollection,
          where('ASSET', '>=', minAssets),
          where('ASSET', '<=', maxAssets)
        );
        const snapshot = await getDocs(assetsQuery);
        const dataSize = snapshot.size;
        setData(dataSize);
      } catch (error) {
        console.error(`Error fetching data for ${title}:`, error);
        setData(0); // Fallback value on error
      }
    };

    fetchData();
  }, [collectionPath, title]);

  // Format the number to one decimal place with "K" if it’s in thousands
  const formatNumber = (num) => {
    console.log('ASSETS: ', num);
    if (num >= 1000) {
      return `${(num / 1000).toFixed(1)}K`;
    }
    return num;
  };

  return (
    <div className="dashboard-tile">
      <div className="tile-title">{title}</div>
      <div className="tile-number">{data !== null ? formatNumber(data) : '...'}</div>
    </div>
  );
}

DashboardTileAssetRange.propTypes = {
  title: PropTypes.string.isRequired,
  collectionPath: PropTypes.string.isRequired,
};

export default DashboardTileAssetRange;