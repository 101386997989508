import React, { useState } from 'react';
import bankDataFields2 from './bankDataFields2';
import './data-dictionary.css';
import { useNavigate } from 'react-router-dom';

const DataDictionary = () => {
  const [viewMode, setViewMode] = useState('Alphabetically');
  const [openGroups, setOpenGroups] = useState({}); // Track open/closed state for each group
  const navigate = useNavigate();

  // Flatten all fields to calculate the total count
  const allFields = bankDataFields2.flatMap(group => group.fields);
  const fieldCount = allFields.length;

  // Toggle a group's open/closed state
  const toggleGroup = (index) => {
    setOpenGroups(prev => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  // Prepare fields based on the selected view mode
  const sortedDataFields = [...allFields].sort((a, b) => a.key.localeCompare(b.key));

  return (
    <div style={styles.container}>
      <button className="fields-back-btn" onClick={() => navigate('/home')}>
        &lt; Home
      </button>
      <div style={styles.content}>
        {/* Header Section */}
        <div style={styles.header}>
          <span style={styles.totalFields}>Total Fields: {fieldCount}</span>
          <select
            style={styles.selector}
            value={viewMode}
            onChange={(e) => setViewMode(e.target.value)}
          >
            <option value="Alphabetically">Alphabetically</option>
            <option value="Logically">Logically</option>
          </select>
        </div>

        <div style={styles.intro}>These are the FDIC fields we mine and leverage across 4.5k banks as part of our client identification strategy.</div>

        {/* Fields Display Section */}
        {viewMode === 'Alphabetically' ? (
          <div style={styles.groupContainer}>
            {sortedDataFields.map((field) => (
              <div key={field.key} style={styles.fieldRow}>
                <span style={styles.fieldKey}>{field.key}</span>
                <span style={styles.fieldTitle}>{field.title}</span>
                {field.unit && <span style={styles.fieldUnit}>Unit: {field.unit}</span>}
                <span style={styles.fieldDescription}>{field.description}</span>
              </div>
            ))}
          </div>
        ) : (
          bankDataFields2.map((group, index) => (
            <div key={index} style={styles.groupContainer}>
              {/* Group Name with Toggle Functionality */}
              <div
                className="group-name"
                onClick={() => toggleGroup(index)}
              >
                {group.groupName}
              </div>
              
              {/* Conditionally Render Fields Based on Open/Closed State */}
              {openGroups[index] && (
                <div>
                  {group.fields.map((field) => (
                    <div key={field.key} style={styles.fieldRow}>
                      <span style={styles.fieldKey}>{field.key}</span>
                      <span style={styles.fieldTitle}>{field.title}</span>
                      {field.unit && <span style={styles.fieldUnit}>Unit: {field.unit}</span>}
                      <span style={styles.fieldDescription}>{field.description}</span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

// Styles Object
const styles = {
  container: {
    backgroundColor: 'var(--background-color-dark)',
    minHeight: '100vh',
    padding: '30px',
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    width: '100%',
    maxWidth: '500px',
    boxSizing: 'border-box',
    color: '#fff',
    marginTop: '30px'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  totalFields: {
    fontSize: '1.1em',
  },
  selector: {
    padding: '5px',
    fontSize: '1em',
    backgroundColor: '#555',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
  },
  intro: {
    color: '#888',
    paddingLeft: '40px',
    paddingRight: '40px',
    paddingTop: '30px'
  },
  groupContainer: {
    backgroundColor: '#555',
    borderRadius: '8px',
    padding: '30px',
    marginBottom: '40px',
    marginTop: '40px'
  },
  fieldRow: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '15px',
  },
  fieldKey: {
    fontWeight: 'bold',
    color: 'orange',
    fontSize: '1.1em',
    marginTop: '20px',
  },
  fieldTitle: {
    color: '#fff',
    marginTop: '5px',
  },
  fieldUnit: {
    color: '#999',
    marginTop: '5px',
    fontSize: '0.9em'
  },
  fieldDescription: {
    color: '#999',
    marginTop: '5px',
    fontSize: '0.8em',
    marginBottom: '10px'
  },
};

export default DataDictionary;