// Home.js
import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { auth } from '../firebaseConfig';
import { useNavigate } from 'react-router-dom';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import TargetBankCountModal from '../fetch/TargetBankCountModal';
import DashboardTileTotalBanks from './DashboardTileTotalBanks';
import DashboardTileAssetRange from './DashboardTitleAssetRange';
import DashboardTileCommLoans from './DashboardTileCommLoans';
import DashboardTileROA from './DashboardTileROA';
import DashboardTileROE from './DashboardTileROE';
import DashboardSiddhaPartners from './DashboardSiddhaPartners';
import DashboardSiddhaTeam from './DashboardSiddhaTeam';
import DashboardTile from './DashboardTile';
import DashboardTileSOA from './DashboardSiddhaOutreach';
import DashboardTileNETINCQ from './DashboardTileNETINC';

import './home.css';

function Home() {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const db = getFirestore();

  const [logMessages, setLogMessages] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleLogout = () => {
    auth.signOut()
      .then(() => {
        localStorage.removeItem('validated');
        navigate('/login');
      })
      .catch((error) => {
        console.error('Error during logout:', error);
      });
  };

  return (
    <div className="home-container">
      <div className="home-content">
        {/* Top-right button container */}
        <div className="top-right-button-container">
          <span className="text-link" onClick={() => navigate('/fields')}>Fields</span>
          <span className="text-link" onClick={handleLogout}>Logout</span>
        </div>

        {/* Dashboard Tiles */}
        <div className="dashboard-wrap">
          <div className="dashboard-container">
            <DashboardTileTotalBanks
              title={
                <>
                  TOTAL<br />BANKS
                </>
              }
              collectionPath="bank" />
            <DashboardTileAssetRange
              title={
                <>
                  ASSETS<br />400M - 12B
                </>
              }
              collectionPath="bank"
            />
            <DashboardTileCommLoans
              title={
                <>
                  COMMERCIAL<br />LOANS &gt; 8B
                </>
              }
              collectionPath="bank"
            />
            <DashboardTileROA
              title={
                <>
                  ROA &gt;<br />1.2%
                </>
              }
              collectionPath="bank"
            />
            <DashboardTileROE
              title={
                <>
                  ROE &gt;<br />12%
                </>
              }
              collectionPath="bank"
            />
            <DashboardTileNETINCQ
              title={
                <>
                  QUARTERLY<br />NET &gt; 2B
                </>
              }
              collectionPath="bank"
            />
            <DashboardTileSOA
              title={
                <>
                  SIDDHA<br />OUTREACH
                </>
              }
              collectionPath="bank"
            />
            <DashboardSiddhaPartners
              title={
                <>
                  SIDDHA<br />PARTNERS
                </>
              }
              collectionPath="bank"
            />
             <DashboardSiddhaTeam
              title={
                <>
                  SIDDHA<br />TOOLS
                </>
              }
              collectionPath="bank"
            />
          </div>
        </div>
      </div>

      {/* Get Target Bank Count Button */}
      <button className="home-btn-wide" onClick={() => setIsModalOpen(true)}>Get Target Bank Count</button>

      {/* Log window for displaying messages */}
      <div className="log-window">
        {logMessages.map((log, index) => (
          <p key={index}>{log}</p>
        ))}
      </div>

      <div className="spacer"></div>

      {/* Target Bank Count Modal */}
      {isModalOpen && (
        <TargetBankCountModal closeModal={() => setIsModalOpen(false)} setLogMessages={setLogMessages} />
      )}

      {/* Centered Logo at the Bottom */}
      <div className="logo-container">
        <img src="/assets/siddha-logo-small.png" alt="Siddha Logo" className="siddha-logo" />
      </div>
    </div>
  );
}

export default Home;