import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Papa from 'papaparse';
import fetchAllInstitutions from '../fetch/FetchAllInstitutions';
import fetchSampleBankDetails from '../fetch/FetchSampleBankDetails';
import fetchAllFinancials from '../fetch/FetchAllFinancials';
import fetchAllLocations from '../fetch/FetchAllLocations';
import fetchAllFEDRSSDs from '../fetch/FetchAllFedRSSDs';
import CSVtoFirestoreUpdater from '../fetch/CSVtoFirestoreUpdater';
import './fetch.css';
import { getFirestore } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions'; // Import Firebase Functions

function Fetch() {
  const navigate = useNavigate();
  const [logMessages, setLogMessages] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);
  const [activeInstitutions, setActiveInstitutions] = useState([]);
  const [completeData, setCompleteData] = useState([]);
  const [showFetchModal, setShowFetchModal] = useState(false);
  const [showCSVUpdater, setShowCSVUpdater] = useState(false);
  const [fetchConfirmText, setFetchConfirmText] = useState('');
  const [status, setStatus] = useState("");
  const logWindowRef = useRef(null);
  const db = getFirestore();
  const repdte = "20240630";

  // Initialize Firebase Functions
  const functions = getFunctions();
  const startFinancialsFetch = httpsCallable(functions, 'fetchFinancialsToCSV');

  // Auto-scroll log window when new messages are added
  useEffect(() => {
    if (logWindowRef.current) {
      logWindowRef.current.scrollTop = logWindowRef.current.scrollHeight;
    }
  }, [logMessages]);

  // Add log message
  const addLogMessage = (message) => {
    setLogMessages((prevLogs) => [...prevLogs, message]);
  };

  // Cloud function trigger for FDIC data fetch
  const handleStartFinancialsFetch = async () => {
    setFetching(true);
    addLogMessage('Initiating cloud-based FDIC data fetch...');
    try {
      const result = await startFinancialsFetch();
      addLogMessage(result.data.message || 'Cloud function completed.');
    } catch (err) {
      addLogMessage(`Error starting FDIC data fetch: ${err.message}`);
    } finally {
      setFetching(false);
    }
  };

  // Handle completion of bank data fetch
  const handleFetchComplete = (data) => {
    setLogMessages((prevLogs) => [
      ...prevLogs,
      `Fetched ${data.length} banks`
    ]);
  };

  // Trigger the modal for fetching bank IDs
  const confirmFetchAllInstitutions = () => {
    setShowFetchModal(true);
  };

  // Function to initiate fetching bank IDs after confirmation
  const handleFetchAllInstitutions = async () => {
    setShowFetchModal(false);
    setFetching(true);
    setLogMessages([]);
    try {
      await fetchAllInstitutions(handleFetchComplete, addLogMessage);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setFetching(false);
      setFetchConfirmText(''); // Reset the confirmation text
    }
  };

  const openCSVUpdater = () => setShowCSVUpdater(true);

  // Fetch additional bank details for a specific CERT
  const handleFetchSampleBankDetails = async () => {
    setFetching(true);
    addLogMessage('Fetching additional bank details for CERT 10002...');
    try {
      const bankData = await fetchSampleBankDetails();
      addLogMessage('Fetched additional bank details successfully.');
      addLogMessage(JSON.stringify(bankData, null, 2));
    } catch (error) {
      console.error('Error fetching additional bank details:', error);
      addLogMessage(`Error: ${error.message}`);
    } finally {
      setFetching(false);
    }
  };

  const handleFetchAllFinancials = async () => {
    setFetching(true);
    addLogMessage('Starting fetch of all bank details...');
    try {
      const totalBanks = await fetchAllFinancials(addLogMessage);
      addLogMessage(`Successfully completed bank details fetch. Total banks processed: ${totalBanks}`);
    } catch (error) {
      console.error('Error fetching bank details:', error);
      addLogMessage(`Error during bank details fetch: ${error.message}`);
    } finally {
      setFetching(false);
    }
  };

  const handleFetchAllFEDRSSDs = async () => {
    setFetching(true);
    addLogMessage('Starting fetch of all bank details...');
    try {
      const totalBanks = await fetchAllFEDRSSDs(addLogMessage);
      addLogMessage(`Successfully completed bank details fetch. Total banks processed: ${totalBanks}`);
    } catch (error) {
      console.error('Error fetching bank details:', error);
      addLogMessage(`Error during bank details fetch: ${error.message}`);
    } finally {
      setFetching(false);
    }
  };

  const handleFetchAllLocations = async () => {
    setFetching(true);
    addLogMessage('Starting fetch of all bank details...');
    try {
      const totalBanks = await fetchAllLocations(addLogMessage);
      addLogMessage(`Successfully completed bank details fetch. Total banks processed: ${totalBanks}`);
    } catch (error) {
      console.error('Error fetching bank details:', error);
      addLogMessage(`Error during bank details fetch: ${error.message}`);
    } finally {
      setFetching(false);
    }
  };

  const fetchData = async () => {
    try {
      setStatus("Starting data fetch...");

      // Construct the endpoint with the REPDTE parameter
      const response = await fetch(`https://us-central1-banklist-app.cloudfunctions.net/fetchBankData?repdte=${repdte}`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.text();
      setStatus(result); // Show the completion message from the function response
    } catch (error) {
      setError(error.message);
      setStatus("Failed to fetch data.");
    }
  };

  // Process institutions in batches
  const processInstitutions = async (institutions) => {
    const batchSize = 10;
    const delay = 1000;

    for (let i = 0; i < institutions.length; i += batchSize) {
      const batch = institutions.slice(i, i + batchSize);
      await processBatch(batch);
      await new Promise((resolve) => setTimeout(resolve, delay));
      setProgress(((i + batchSize) / institutions.length) * 100);
      addLogMessage(`Processed batch ${i / batchSize + 1}`);
    }

    generateCSV();
  };

  // Process each batch of institutions
  const processBatch = async (batch) => {
    const results = await Promise.all(batch.map(fetchInstitutionData));
    setCompleteData((prevData) => [...prevData, ...results.filter(Boolean)]);
  };

  // Fetch institution data
  const fetchInstitutionData = async (institution) => {
    try {
      const [institutionDetails, financials] = await Promise.all([
        fetchDetails(institution.CERT),
        fetchFinancials(institution.CERT),
      ]);
      return { ...institutionDetails, ...financials };
    } catch (err) {
      console.error(`Error fetching data for institution ${institution.CERT}:`, err);
      return null;
    }
  };

  // Fetch institution details
  const fetchDetails = async (cert) => {
    const response = await fetch(`https://banks.data.fdic.gov/api/institutions/${cert}?fields=CERT,NAME,CITY,STNAME,ZIP,ASSET,DEP,DEPDOM,ROA,ROE`);
    const data = await response.json();
    return data.data[0];
  };

  // Fetch institution financials
  const fetchFinancials = async (cert) => {
    const response = await fetch(`https://banks.data.fdic.gov/api/financials?filters=CERT:${cert}&fields=CERT,REPDTE,ASSET,DEP,LNLSNET,LNRE,INTINC,EINTEXP,NONII,NONIX&sort_by=REPDTE&sort_order=DESC&limit=1`);
    const data = await response.json();
    return data.data[0] || {};
  };

  // Generate CSV and trigger download
  const generateCSV = useCallback(() => {
    const csv = Papa.unparse(completeData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'fdic_data.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
    addLogMessage('CSV generated and downloaded.');
  }, [completeData]);

  return (
    <div className="fetch-container">
      <button className="navigate-home-btn" onClick={() => navigate('/home')}>
        &lt; Home
      </button>

      <button className="fetch-btn-wide" onClick={confirmFetchAllInstitutions} disabled={fetching}>
        Fetch All Institutions
      </button>

      <button className="fetch-btn-wide" onClick={handleFetchAllFinancials} disabled={fetching}>
        Fetch All Financials
      </button>

      <button className="fetch-btn-wide" onClick={handleFetchAllFEDRSSDs} disabled={fetching}>
        Fetch All FED_RSSDs
      </button>

      <button className="fetch-btn-wide" onClick={handleFetchAllLocations} disabled={fetching}>
        Fetch All Locations
      </button>

      <button className="fetch-btn-wide" onClick={openCSVUpdater} disabled={fetching}>
        Update Banks from CSV
      </button>

      <button className="fetch-btn-wide" onClick={handleFetchSampleBankDetails} disabled={fetching}>
        Fetch & Log Sample Data
      </button>

      <button onClick={fetchData}>Fetch Bank Data for REPDTE {repdte}</button>
      {status && <p>Status: {status}</p>}
      {error && <p style={{ color: "red" }}>Error: {error}</p>}

      <div className="log-window" ref={logWindowRef}>
        {logMessages.map((log, index) => (
          <p key={index}>{log}</p>
        ))}
        {error && <p style={{ color: 'red' }}>Error: {error}</p>}
      </div>

      <p>Progress: {progress.toFixed(2)}%</p>
      {progress === 100 && <p>Data scraping complete. CSV download should start automatically.</p>}

      {showCSVUpdater && (
        <CSVtoFirestoreUpdater closeUpdater={() => setShowCSVUpdater(false)} />
      )}
      
      <div className="logo-container">
        <img 
          src="/assets/siddha-logo-small.png" 
          alt="Siddha Logo" 
          className="siddha-logo"
        />
      </div>
    </div>
  );
}

export default Fetch;