import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as XLSX from 'xlsx';
import { db } from '../firebaseConfig';
import { writeBatch, doc, getDoc, setDoc } from 'firebase/firestore';
import './csv-modal.css';

function CSVtoFirestoreUpdater({ closeUpdater }) {
  const [logMessages, setLogMessages] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const logWindowRef = useRef(null); // Ref to track the log window

  useEffect(() => {
    // Scrolls to the bottom whenever logMessages changes
    if (logWindowRef.current) {
      logWindowRef.current.scrollTop = logWindowRef.current.scrollHeight;
    }
  }, [logMessages]);

  const handleFileUpload = async (event) => {
    setIsProcessing(true);
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = async (e) => {
      const csvText = e.target.result;
      try {
        const workbook = XLSX.read(csvText, { type: 'binary' });
        const sheet = workbook.Sheets[workbook.SheetNames[0]];
        const data = XLSX.utils.sheet_to_json(sheet, { defval: 0 });

        let totalProcessed = 0;
        let batch = writeBatch(db);
        let operationCount = 0;

        setLogMessages((prevLogs) => [
          ...prevLogs,
          `Starting CSV upload with ${data.length} entries...`
        ]);

        for (const row of data) {
          const cert = row.CERT ? row.CERT.toString().trim() : null;
          if (!cert) continue;

          const bankRef = doc(db, 'bank', cert);

          // Check if the document exists
          const docSnap = await getDoc(bankRef);

          if (docSnap.exists()) {
            // If document exists, update the fields with the new data
            batch.update(bankRef, row);
            setLogMessages((prevLogs) => [
              ...prevLogs,
            ]);
          } else {
            // If document doesn't exist, create a new document with the data
            batch.set(bankRef, row);
            setLogMessages((prevLogs) => [
              ...prevLogs,
              `Created new bank with CERT ${cert}`
            ]);
          }

          operationCount++;
          totalProcessed++;

          if (operationCount === 500) {
            await batch.commit();
            setLogMessages((prevLogs) => [
              ...prevLogs,
              `Batch of 500 committed. Total processed so far: ${totalProcessed}`
            ]);
            batch = writeBatch(db);
            operationCount = 0;
          }
        }

        // Commit any remaining entries in the final batch
        if (operationCount > 0) {
          await batch.commit();
          setLogMessages((prevLogs) => [
            ...prevLogs,
            `Final batch committed. Total banks processed: ${totalProcessed}`
          ]);
        }

        setLogMessages((prevLogs) => [...prevLogs, `CSV processing complete. Processed ${totalProcessed} entries.`]);
      } catch (error) {
        console.error('Error updating Firestore from CSV:', error);
        setLogMessages((prevLogs) => [
          ...prevLogs,
          `Error: ${error.message}`
        ]);
      } finally {
        setIsProcessing(false);
      }
    };
    reader.readAsBinaryString(file);
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Upload CSV File</h2>
        <input type="file" accept=".csv" onChange={handleFileUpload} />
        <button onClick={() => closeUpdater(logMessages)} disabled={isProcessing} className="close-button">
          Close
        </button>
        <div className="csv-log-window" ref={logWindowRef}>
          {logMessages.map((log, index) => (
            <p key={index}>{log}</p>
          ))}
        </div>
      </div>
    </div>
  );
}

// Define PropTypes for the component
CSVtoFirestoreUpdater.propTypes = {
  closeUpdater: PropTypes.func.isRequired,
};

export default CSVtoFirestoreUpdater;