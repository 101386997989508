import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from '../firebaseConfig';
import { onAuthStateChanged, reload } from 'firebase/auth';
import PropTypes from 'prop-types';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [emailVerified, setEmailVerified] = useState(false);
  const [loadingAuth, setLoadingAuth] = useState(true); // Secondary loading state for user reload

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log('User is logged in:', user.email);
        setLoadingAuth(true); // Start secondary loading during reload
        reload(user)
          .then(() => {
            console.log('User reloaded, email verified:', user.emailVerified);
            setCurrentUser(user);
            setEmailVerified(user.emailVerified);
          })
          .catch((error) => {
            console.error('Error reloading user:', error);
          })
          .finally(() => {
            setLoadingAuth(false); // End secondary loading after reload
          });
      } else {
        console.log('No user is logged in');
        setCurrentUser(null);
        setEmailVerified(false);
        setLoadingAuth(false); // End secondary loading if no user
      }
      setLoading(false); // End primary loading for initial auth check
    });

    return unsubscribe;
  }, []);

  // Wait for both primary loading and reload loading to finish
  if (loading || loadingAuth) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider value={{ currentUser, emailVerified, setEmailVerified }}>
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};