// DashboardTile.js
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import './dashboard-tile.css';

function DashboardSiddhaTeam({ title, collectionPath }) {
  const [data, setData] = useState(null);

  return (
    <div className="dashboard-tile">
      <div className="tile-title">{title}</div>
      <div className="tile-number">4</div>
    </div>
  );
}

DashboardSiddhaTeam.propTypes = {
  title: PropTypes.string.isRequired,
  collectionPath: PropTypes.string.isRequired,
};

export default DashboardSiddhaTeam;