import axios from 'axios';
import Papa from 'papaparse';

const FIELDS = [
  "CERT", "RSSDID", "CBLRIND", "SC", "QBPRCOMLDESC", "ELNANTR", "ENDEFYMD", "NPERFV", "LNRESNCR", "CB", "REGAGNT", 
  "NUMEMP", "ASSET", "NCREMULR", "NCRECONR", "STCNTY", "NALTOT", "IDLNCORR", "SPECGRPDESC", "SZLAUTO", 
  "CITY", "P9ASSET", "STMULT", "P3ASSET", "DEPALLEX", "LIAB", "ITAX", "TRADE", "NIMY", "INSAGNT1", "DEPINS", 
  "BKCLASS", "NOIJ", "NOIJY", "IDNCCIR", "EFFDATE", "LSAORE", "ELNATR", "NTRER", "INTINC", "NETINBM", 
  "EQCSTKRX", "UC", "LSALNLS", "NTLNLS", "FLDOFF", "ERNASTR", "NCLNLSR", "LNLSNET", "DEPFOR", "NCRENRER", 
  "PARCERT", "ROA", "P3LTOT", "RISDATE", "EQCDIV", "ROEINJR", "ROE", "SZLNHEL", "OCCDISTDESC", "EQ", 
  "NAME", "HCTMULT", "SZLNCRCD", "NONIXAY", "SZLNOTH", "FREPO", "FDICDBSDESC", "CHBAL", "LSAOA", "OFFDOM", 
  "SUBCHAPS", "ASDRRES", "NCRER", "AOAR", "ORE", "ACTIVE", "NTRECOSR", "NCRERESR", "DEPIDOM", "REPDTE", 
  "IDNTCIR", "INTEXPY", "RBC1AAJ", "NTRERESR", "ASTEMPM", "AOA", "ROAPTX", "PTAXNETINC", "DEPDASTR", 
  "NCCOMRER", "DEPBEFEX", "FEDDESC", "NONIIAY", "IDNTCONR", "ELNATRY", "NONIX", "LNLSNTV", "DEP", "CBSA", 
  "IDNCOTHR", "NIM", "RBCRWAJ", "SZLNCI", "LSASCDBT", "IDNCGTPR", "NAMEFULL", "IDERNCVR", "NFAA", "ESTYMD", 
  "NONII", "TRUST", "EQTOT", "ID", "CD1T3", "CD1T3R", "CD3LES", "CD3LESR", "CD3LESS", "CD3LESSR", 
  "CDOV3", "CDOV3R", "CD3T12", "CD3T12R", "LNCI", "LNCI RATIO", "LIPMTG", "LNCON", "LNCRCD", "LNRE", 
  "LNREMULT", "LNRELOC", "LNRENRES", "IGLSEC", "IGLSECR", "INSALL", "INSFDIC", "LIABR", "INTINCR", "INTINCY", 
  "FDICDBS", "FDICSUPV", "COREDEP", "COREDEPR", "DRCON", "DRCONR", "DRCRCD", "DRCRCDR", "EQR", "VOLIAB", 
  "VOLIABR", "IDT1CER", "IDT1RWAJR", "SCMUNIAA", "SCMUNIAF", "ESTINS", "IDDEPINR"
];

// Modify the API URL to include sorting and limit
const FDIC_API_URL = 'https://banks.data.fdic.gov/api/financials?format=json&filters=CERT:10002&sort_by=REPDTE&sort_order=DESC&limit=1';

const fetchSampleBankDetails = async () => {
  try {
    const response = await axios.get(FDIC_API_URL);
    const bankData = response.data.data;

    const filteredBankData = bankData.map(record => {
      const data = record.data;
      return FIELDS.reduce((obj, field) => {
        obj[field] = data.hasOwnProperty(field) ? data[field] : null;
        return obj;
      }, {});
    });

    // Convert to CSV and trigger download
    const csv = Papa.unparse(filteredBankData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'Banklist-Financial-Data.csv';
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    console.log('CSV generated and download triggered.');
    return filteredBankData;

  } catch (error) {
    console.error('Error fetching additional bank details:', error);
    throw error;
  }
};

export default fetchSampleBankDetails;