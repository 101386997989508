// src/fetch/FetchAllBankDetails.js

import PropTypes from 'prop-types';
import { db } from '../firebaseConfig';
import axios from 'axios';
import { collection, doc, writeBatch } from 'firebase/firestore';

/**
 * FDIC API Configuration
 */
const FDIC_API_URL = 'https://banks.data.fdic.gov/api/institutions';
const LIMIT_PER_REQUEST = 100; // Number of institutions to fetch per request

/**
 * Function to fetch all active financial institutions from the FDIC API
 * and write them to the Firestore `/bank` collection.
 * Only institutions with ACTIVE == 1 and INACTIVE == 0 are processed.
 *
 * @param {Function} onComplete - Callback function invoked upon completion or error.
 * @param {Function} onLog - Callback function for logging messages.
 */
const fetchAllInstitutions = async (onComplete, onLog) => {
  let offset = 0; // Start at the first bank
  const limit = LIMIT_PER_REQUEST; // Number of banks to fetch per request
  let totalFetched = 0; // Counter for total banks processed

  try {
    while (true) {
      // Construct the API query parameters
      const params = {
        format: 'json',
        limit: limit,
        offset: offset,
        sort: 'CERT ASC', // Ensure consistent ordering
      };

      // Log the current fetch operation
      onLog(`Fetching data with offset: ${offset}`);

      // Fetch data from the FDIC API
      const response = await axios.get(`${FDIC_API_URL}`, { params });
      const banksData = response.data.data;

      // Check if there's no more data to fetch
      if (!Array.isArray(banksData) || banksData.length === 0) {
        onLog('No more bank data available. Fetching process completed.');
        break;
      }

      // Initialize Firestore batch for batched writes
      const batch = writeBatch(db);

      for (const bankInfo of banksData) {
        const bankDetails = bankInfo.data;

        // Apply filtering: ACTIVE == 1 and INACTIVE == 0
        if (bankDetails.ACTIVE === 1 && bankDetails.INACTIVE === 0) {
          const cert = bankDetails.CERT.toString();

          // Log the CERT being processed
          onLog(`Processing CERT: ${cert}`);

          // Reference to the Firestore document in the 'bank' collection
          const bankRef = doc(collection(db, 'bank'), cert);

          // Set Firestore document with bankDetails using FDIC-provided keys
          // Using { merge: true } to update existing documents without overwriting entire data
          batch.set(bankRef, bankDetails, { merge: true });
          totalFetched++;
        } else {
          // Log skipped CERTs for visibility
          onLog(
            `Skipping CERT: ${bankDetails.CERT} (ACTIVE: ${bankDetails.ACTIVE}, INACTIVE: ${bankDetails.INACTIVE})`
          );
        }
      }

      // Commit batch to Firestore
      await batch.commit();
      onLog(`Batch committed. Total banks processed so far: ${totalFetched}`);

      // Increment offset to fetch the next batch of banks
      offset += limit;

      // Optional delay to avoid rate limits (adjust as needed)
      await new Promise((resolve) => setTimeout(resolve, 1000)); // 1-second delay
    }

    // Log the completion message
    onLog(`Total banks processed and written to Firestore: ${totalFetched}`);
    onComplete(`Fetched and updated ${totalFetched} banks.`);
  } catch (error) {
    // Log the error message
    onLog(`Error fetching or updating bank data: ${error.message}`);
    onComplete(`Error: ${error.message}`);
  }
};

// Prop validation for onComplete and onLog
fetchAllInstitutions.propTypes = {
  onComplete: PropTypes.func.isRequired,
  onLog: PropTypes.func.isRequired,
};

export default fetchAllInstitutions;